import { HeadFC, graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation as NavigateSwiper } from "swiper";
import Footer from "../../components/footer/footer";
import Navigation from "../../components/navigation/navigation";
import SEO from "../../components/seo/seo";
import StylesComponent from "../../components/styles/styles-component";
import { experiences_list, numericOrdinal } from "..";

// CSS
import "../../sass/page-specific/event-styles.scss";
import "../../sass/page-specific/homepage-styles.scss";
import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/navigation"
import PackageEnquiry from "../../components/package-enquiry/package-enquiry";
import SomethingElseCta from "../../components/ctas/something-else-cta";
import CookiesConsentNotice from "../../components/cookies-consent/cookies-consent";


const Experiences: React.FC = (): JSX.Element => {
   useEffect(() => {
      window.scrollTo(0, 0)
   }, [])

   const [selectedExperience, setSelectedExperience] = useState<string>("private_jet")

   return (
      <React.Fragment>
         <StylesComponent/>
         <Navigation/>
         <CookiesConsentNotice/>

         <div className="event-hero-outer-container" style={{ backgroundImage: `url(https://images.ctfassets.net/welt89o8b674/4Id00aszxaR5aWTXu1zxzJ/5825b4b6e8ac12ac0c99c5703ce068d7/maldives-experience.jpg)`}}>
            <div className="event-hero-container-overlay">
               <div className="event-hero-text-container container-width">
                  <h1>Experiences</h1>
                  <p className="secondary-text">Dream your own custom luxury experience</p>

                  <br/>

                  <a href="#package-enquiry" className="standard-button orange">Make enquiry</a>
               </div>
            </div>
         </div>

         <div className="standard-event-outer-container">
            <div className="standard-event-inner-container container-width">
               <h2>Featured experiences</h2>

               <br/>
               <br/>

               <div className="experiences-grid-container">
                  <ul className="experiences-title-list white">
                     {experiences_list.map((list_item) => (
                        <li className={selectedExperience === list_item.id ? 'active' : ''} onClick={() => setSelectedExperience(list_item.id)}><span>{list_item.name}</span></li>
                     ))}
                  </ul> 

                  <select 
                     className="experiences-title-sm-selector"
                     value={selectedExperience}
                     onChange={(e) => setSelectedExperience(e.target.value)}
                  >
                  <option value="" disabled>Select an experience</option>
                     {experiences_list.map((list_item) => (
                        <option value={list_item.id}>{list_item.name}</option>
                     ))}
                  </select>
                  
                  <div className="experiences-content-container">
                  {
                     experiences_list.map((list_item) => (
                        <div className={`experience-card white ${selectedExperience === list_item.id ? 'active' : ''}`}>
                        <div className="experience-card-image" id={list_item.id}/>

                        <h3>{list_item.tagline}</h3>

                        <p>{list_item.text}</p>

                        <br/>

                        <a href={list_item.url} className="standard-button orange">Find out more</a>
                        </div>
                     ))
                  }
                  </div>
               </div>
            </div>
         </div>

         <PackageEnquiry
            eventImage="https://images.ctfassets.net/welt89o8b674/1KoMcdXq67BLBbJhzAdm8W/0660f903cd3c5d7bd9c601bfd89f4694/turkey-balloons-experience.jpg"
            title="Request a custom experience"
            subheading="We'll get organise everything for you, just as you dreamed it"
            eventPlaceholder="Private jet to the Maldives"
         />

         <SomethingElseCta
            title="Prefer to give us a call?"
         />

         <Footer/>
      </React.Fragment>
   )
}

export const Head: HeadFC = () => (
   <SEO
      title="Experiences | Prestige VIP"
      metaDescription="Experience the exceptional with exclusive experiences available across the globe. Experience luxury and VIP like nowhere else with Prestige VIP."
      slug="/experiences"
   />
)

export default Experiences